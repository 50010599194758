.file-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--bg-color);
    margin-top: var(--header-height);
    align-items: center;
    justify-content: flex-start;
    background-color: white;
}

.search-bar {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    width: 100%;
    max-width: 1000px;
}

.search-bar > input {
    font-size: 16px;
    border: none;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.pdf-list {
    display: flex;
    width: 100%;
    max-width: 1000px;
    flex-direction: column;
    align-items: flex-start;
}

.pdf-list .letter-header {
    width: 100%;
    margin-top:5px;
    margin-bottom: 5px;
    padding: 0;
    background-color: #6c8a1fff;
    cursor: pointer;
}

.pdf-list .letter-header .header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.pdf-list .letter-header .header-row .letter {
    display: flex;
    margin: auto;
    flex-grow: 1;
}

.pdf-list .letter-header .header-row .arrow {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    margin-right: 20px;
    color: white;    
}

.pdf-list .letter-header .header-row h2 {
    margin: 0;
    font-size: clamp(3vw, 6vw, 2em);
}

.pdf-list .letter-header h1 {
    color: white;
    margin: 0;
    margin-left: 20px;
    font-size: clamp(2rem, 10vw, 3rem);
    font-family: "Myriad Pro Bold";

}

.pdf-list .letter-file p {
    color: #262f3aff;
    margin: 0;
    margin-left: 20px;
    font-size: clamp(1rem, 10vw, 2rem);
    padding-top: 10px;
    font-family: "Myriad Pro Light";
}

.pdf-list .letter-file .page-number,
.pdf-list .letter-header .file-location {
    font-size: clamp(0.5rem, 5vw, 1rem);
    padding-top:0
}

.pdf-list .letter-file .page-number {
    font-size: clamp(0.5rem, 5vw, 1rem);
    padding-top:0;
    padding-bottom: 10px;
}

.letter-files .letter-file {
    background-color: #c1d59f;
}

.pdf-list .letter-files a:not(:first-child) .letter-file{
    border-top: solid 1px #95c372;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}