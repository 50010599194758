
.main-menu {
    position: fixed;
    height: calc(100% - var(--main-page-offset));
    top: var(--main-page-offset);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    --menu-item-width: 80%;
    font-size: 1em;
}

.main-menu h1 {
    margin: 0;
    font-size: clamp(1vw, 12vw, 4em);
}

.main-menu .subtitle h1 {
    color: white;
}

.main-menu .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    position: relative;
}

.main-menu .menu-item, .main-menu .menu > a{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}

.main-menu .menu-item > .title {
    height: fit-content;
    width: var(--menu-item-width);
}

.main-menu .menu-item > .subtitle {
    height: fit-content;
    width: var(--menu-item-width);
}

.main-menu .menu-bar {
    height: 2%;
    width: var(--menu-item-width);
    margin-bottom: 50px;
}

.main-menu .menu>a:nth-child(1) .menu-item .menu-bar {
    background-color: #c3dcb0ff;
}

.main-menu .menu>a:nth-child(2) .menu-item .menu-bar {
    background-color: #e1601eff;
}

.main-menu .menu>a:nth-child(3) .menu-item .menu-bar {
    background-color: #6c8a1fff;
}

.main-menu .menu>a {
    position: relative;
}

.main-menu .menu>a:nth-child(1) {
    color: #c3dcb0ff;
}

.main-menu .menu>a:nth-child(2) {
    color: #e1601eff;
}

.main-menu .menu>a:nth-child(3) {
    color: #6c8a1fff;
}

.main-menu .menu>a:nth-child(1)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #262f3ab5; /* change the color and opacity as needed */
    z-index: -1;
    }

.main-menu .menu>a:nth-child(1)::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../Assets/Country Home.png");
    background-position: center;
    background-size: cover;
    z-index: -2;
}

.main-menu .menu>a:nth-child(2)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #262f3ab5; /* change the color and opacity as needed */
    z-index: -1;
    }

.main-menu .menu>a:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../Assets/Rural Home 1.png");
    background-position: center;
    background-size: cover;
    z-index: -2;
}

.main-menu .menu>a:nth-child(3)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #262f3ab5; /* change the color and opacity as needed */
    z-index: -1;
    }

.main-menu .menu>a:nth-child(3)::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../Assets/Rural Home 2.png");
    background-position: center;
    background-size: cover;
    z-index: -2;
}

@media only screen and (min-width: 800px) {

    .main-menu .menu>a:nth-child(1)::before {
        display: none;
    }
    .main-menu .menu>a:nth-child(2)::before {
        display: none;
    }
    .main-menu .menu>a:nth-child(3)::before {
        display: none;
    }

    .main-menu .menu>a:nth-child(1)::after {
        background: #6c8a1fff !important;
    }
    .main-menu .menu>a:nth-child(2)::after {
        background: #404040ff !important;
    }
    .main-menu .menu>a:nth-child(3)::after {
        background: #262f3aff !important;
    }
}