.header-container {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--header-height);
  z-index: 10;
}

.horizontal-header-container {
  --header-height: calc(20.5vh * 0.695);
  height: var(--header-height);
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: var(--header-height);
    background-color: #262f3aff;
    z-index: 4;
    clip-path: polygon(0 69.5%, 0 0, 100% 0, 100% 44%, 16% 80.5%);
    transition: clip-path 0.2s ease-in-out;
  }

.horizontal-header {
  clip-path: polygon(0 75.5%, 0 0, 100% 0, 100% 75.5%, 16% 75.5%);
}

.header-triangle {
    position: fixed;
    top: 0;
    width: 100%;
    height: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #353e4aff;
    z-index: 3;
    clip-path: polygon(0 89%, 0 0, 100% 0, 100% 63.5%, 16% 100%);
    transition: clip-path 0.2s ease-in-out;
  }

  .horizontal-header-triangle {
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%, 16% 100%);
  }

.header-content {
  display: grid;
  grid-template-columns: 1.5fr 7fr 1fr;
  grid-template-rows: 1.5fr 2.5fr 1.5fr;
  height: 45%;
  max-width: 100%;
  color: white;
  align-items: center;
  margin: 0;
}

.horizontal-header-content {
  height: 64.75%;
}

.fa-lg {
  font-size: 2em !important;
}

.header-content .back {
  height: 50%;
  width: 75%;
  display: flex;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
  max-height: 75%;
  margin-left: 15px;
  cursor: pointer;
}

.header-content .back h1 {
  font-family: "Myriad Pro Light";
  font-weight: 100;
  cursor: pointer;
}

.header-content h1 {
  grid-column-start: 1;
  grid-column-start: 2;
  margin: 0;
  margin-left: 10px;
  grid-row-start: 2;
  grid-row-end: 3;
  font-size: clamp(1em, 6.5vw, 3em);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.header-content p {
  margin: 0;
  margin-top: 10px;
  margin-left: 10px;
  grid-column-start: 1;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  font-size: clamp(1vw, 5vw, 1.5em);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo-menu-button {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 3vw;

}

.header-container .side-menu {
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;
  top: 0;
  right: -100vw;
  width: 90%;
  max-width: 700px;
  background: rgb(91,130,152);
  background: linear-gradient(180deg, rgba(91,130,152,1) 0%, rgba(0,46,84,1) 85%);
  transition: right 0.25s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.side-menu {
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
}

/* For Chrome, Edge, and Safari */
.side-menu::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(165, 165, 165, 0.5);
}

/* For Chrome, Edge, and Safari */
.side-menu::-webkit-scrollbar-thumb {
  background-color: rgba(165, 165, 165, 1);
  border-radius: 3px;
}

.side-menu-overlay.open {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
}

.header-container .side-menu.open {
  right: 0;
}

.header-container .side-menu .side-menu-header {
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container .side-menu .side-menu-header .side-menu-logo-img{
  max-height: 100px;
}

.header-container .side-menu .side-menu-options {
  width: 80%;
  text-align: right;
}

.header-container .side-menu .side-menu-options a,
.header-container .side-menu .side-menu-options p{
  font-family: 'Myriad Pro Light';
  color: white;
  font-size: 1.5em;
  letter-spacing: -1px;
}

