.pdf-document {
    max-width: 100vw !important;
    height: 100%;
    position: relative;
}

.pdf-page {
    --pdf-start: calc(var(--header-height)*0.695);
    margin-top: var(--pdf-start);
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--pdf-start));
    background-color: var(--bg-color);
   
  }

.pdf-toolbar {
    display: flex;
    position: sticky;
    z-index: 1;
    background-color: rgba(211, 211, 211, 0.85);
    top: 0%;
    padding: 5px;
    /* border-radius: 5px; */
    border: solid 1px lightgrey;
    justify-content: center;
}

/* .pdf-toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: rgba(211, 211, 211, 0.85);
    padding: 10px;
    border-radius: 5px;
    border: solid 1px lightgrey;
} */

.rpv-page-navigation__current-page-input .rpv-core__textbox {
    width: 3em !important;
}

.page-nav {
    display: flex;
    align-items: center;
  }
  
.page-nav span {
white-space: nowrap;
}

@media only screen and (max-width: 400px) {
    .pdf-toolbar {
        width: 100%;
    }
    .rpv-core__tooltip-body {
        display: none;
    }
}