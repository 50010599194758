.splash {
    top: 0;
    width: 100%;
    height: 100vh;
    background: radial-gradient(circle at center 35.5%, rgba(91,130,152,1) 0, #002e54 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    position: fixed;
}

.border {
    height: 80%;
    width: 70%;
    border: 7px solid #231f20
    /* background-color: ; */
    /* clip-path: polygon( 0 0, 100% 0, 100% 100%, 0 100%, 0 44%, 3% 44%, 3% 98.8%, 97% 98.8%, 97% 1.2%, 3% 1.2%, 3% 27%, 0 27%) */
}

.tagline {
    position: absolute;
    top: 60%;
    left: 0;
    background-color: #231f20;
    width: 60%;
    max-width: 600px;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    white-space: pre-wrap;

}

.tagline h2{
    width: 100%;
    text-align: left;
    margin: 0;
    margin-left: 15vw;
    color: white;
    font-family: "Myriad Pro Bold";
    font-size: 180%;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    padding-right: 10px;
}


.splash-logo-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: radial-gradient(circle at center 35.5%, rgba(91,130,152,1) 0, #002e54 50%);
    z-index: 999;
    position: absolute;
    clip-path: polygon(0 25%, 20% 25%, 20% calc(25% + 120px), 0 calc(25% + 120px));

    /* position: absolute;
    top: 31.5%;
    left: 7%; */
}

.splash-logo {
    position: relative;
    top: 18.5%;
    left: -52px;
    height: 130px;
    max-width: 500px;
    z-index: 1000;
}

.hidden {
    display: none;
}

