.not-found {
    top: 0;
    width: 100%;
    height: 100vh;
    background: #002e54;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    position: fixed;
}