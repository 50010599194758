.sub-menu {
    position: fixed;
    height: calc(100% - var(--main-page-offset));
    top: var(--main-page-offset);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    --menu-item-width: 80%;
}

.sub-menu > .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
}

.sub-menu .menu .menu-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.sub-menu .menu-item, .sub-menu .menu > a{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

.sub-menu .menu a:nth-child(1) .menu-item {
    justify-content: center;
    color: #262f3aff;
    margin-top: 20px;
}

.sub-menu .menu-bar {
    height: 0.3em;
    width: var(--menu-item-width);
    margin-top: 5px;
    margin-bottom: 5px;
}

.sub-menu .menu a:nth-child(1) .menu-item .menu-bar {
    background-color: 
    #262f3aff;
}

.sub-menu .menu a:nth-child(2) .menu-item {
    justify-content: center;
    color: #6c8a1fff;
}

.sub-menu .menu a:nth-child(2) .menu-item .menu-bar {
    background-color: #6c8a1fff;
}

.sub-menu .menu-item > .title {
    height: fit-content;
    width: var(--menu-item-width);
}

.sub-menu .menu-item > .title .bottom {
    color: white;
}

.sub-menu .menu-item > .subtitle {
    height: fit-content;
    width: var(--menu-item-width);
    margin: 0;
    color: white;
}

.sub-menu .menu h3, .sub-menu .menu h1, .sub-menu .menu h4 {
    margin: 0;
}

.sub-menu .menu h1 {
    font-size: clamp(1vw, 13vw, 4em);
}

.sub-menu .menu>a:nth-child(1) {
    background-color: #6c8a1fff;
}

.sub-menu .menu>a:nth-child(2) {
    background-color: #262f3aff;
}