@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('./Assets/fonts/myriad/MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('./Assets/fonts/myriad/MYRIADPRO-BOLD.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('./Assets/fonts/myriad/MYRIADPRO-SEMIBOLD.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('./Assets/fonts/myriad/MyriadPro-Light.woff') format('woff');
}
  

:root {
  --main-color: rgb(208, 208, 208);
  --bg-color: white;
  --accent-color: rgb(89, 89, 89);
  --header-height: 20.5vh;
  --footer-height: 10vh;
  --main-page-offset: calc(var(--header-height) * 0.63);
  scrollbar-width: thin;
}

* {
  font-family: "Myriad Pro Regular";
}

h1 {
  font-family: "Myriad Pro Bold";
}

h2, h3, h4 {
  font-family: "Myriad Pro Semibold";
}

input, textarea, select, button {
  font-size: 16px; /* Or any other font size */
  max-height: 100%; /* Prevent the input from growing beyond the screen height */
}

.app-container {
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.icon {
  fill: var(--accent-color) !important
}

.page-card {
  display: inline-block;
  justify-content: center;
  align-items: center;
  background-color:rgb(237, 255, 132);
  width: 50%;
  padding: 2em;
  border-radius: 20px;
}

.card-title {
  text-align: center;
}

.material-icons {
  height: 20px;
}